/* eslint-disable no-console */

/**
 * Permet de logger une erreur dans la console
 * @param {String} message - le messag à logger
 */
export const logError = function (message) {
  console.error(message)
}

export const logWarn = function (message) {
  console.warn(message)
}

export const logger = {
  error: logError,
  warn: logWarn,
}