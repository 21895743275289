import { ROUTE_NAMES } from '@/modules/patient/constants'

import { getFromAPI } from '@/services/api'
import store from '@/store/instance'
import NovaTools from '@/nova-tools/NovaTools'

import { getEnv } from '@/utils/functions/env.js'

export default {
  isPartnerAccess () {
    return getEnv('VUE_APP_PARTNER_ENV') === 'true'
  },

  setPartnerAccess (isPartnerAccess) {
    localStorage.setItem('isPartnerAccess', JSON.stringify(isPartnerAccess))
  },

  isPartnerAccessLoggedOut () {
    if (! this.isPartnerAccess()) {
      return false
    }
    const isPartnerAccessLoggedOut = localStorage.getItem('isPartnerAccessLoggedOut')

    return (isPartnerAccessLoggedOut !== null) ? JSON.parse(isPartnerAccessLoggedOut) : false
  },

  setPartnerAccessLoggedOut (isPartnerAccessLoggedOut) {
    localStorage.setItem('isPartnerAccessLoggedOut', JSON.stringify(isPartnerAccessLoggedOut))
  },

  removeLocalPartnerAccessLoggedOut () {
    localStorage.removeItem('isPartnerAccessLoggedOut')
  },

  isPartnerAuthentication (to) {
    if (! this.isPartnerAccess()) {
      return false
    }

    return (to.path === '/' && !! to.query?.partnerKey)
  },
  async getRoutePartnerAccess (query) {
    if (query.patientIns) {
      return await this.routePartnerPatientInsAccess(query.patientIns)
    }
    if (query.patientIdentity) {
      return await this.routePartnerPatientIdentityAccess(query.patientIdentity)
    }
    return '/'
  },
  async routePartnerPatientInsAccess (patientIns) {
    try {
      const { data } = await getFromAPI(
        '/api/v1/partner/search_patient', { data: patientIns }, { errorHandle: false })
      if (data.patientId && data.patientId !== null) {
        return {
          name: ROUTE_NAMES.PATIENT_EDIT,
          params: { uuid: data.patientId },
        }
      }
    } catch(e) {}
    return { name: ROUTE_NAMES.PATIENT_CREATE }
  },
  async routePartnerPatientIdentityAccess (patientIdentity) {
    let patientIdentityDecrypted = {}
    try {
      const { data } = await getFromAPI('/api/v1/partner/search_patient',
        { data: patientIdentity }, { errorHandle: false })

      if (data.transactionId) {
        store.commit('patient/SET_PARTNER_TRANSACTION_ID', data.transactionId)
      }
      if (data.patientId && data.patientId !== null) {
        return {
          name: ROUTE_NAMES.PATIENT_EDIT,
          params: { uuid: data.patientId },
        }
      }
      if (data.birthName && data.firstName && data.birthDate && data.birthPlaceCode && data.gender) {
        const dataDecrypted = new URLSearchParams({
          ...data,
          familyName: data.birthName,
          firstNames: data.firstName,
        })
        patientIdentityDecrypted = btoa(dataDecrypted.toString())
      }
    } catch(e) {
      NovaTools.notify.error(e.message)
    }

    return {
      name: ROUTE_NAMES.PATIENT_CREATE,
      params: { patientIdentity: patientIdentityDecrypted },
    }
  },
  postMessageToPartner (message) {
    this.isPartnerAccess() && window.parent.postMessage({ message }, '*')
  },
  postPatientToPartner (patient) {
    patient && this.postMessageToPartner({
      ins: patient.insIdentity?.ins,
      oid: patient.insIdentity?.oid,
      status: patient.insIdentity?.status,
      gender: patient.gender,
      civility: patient.civility,
      birthName: patient.birthName,
      familyName: patient.familyName,
      firstName: patient.firstName,
      firstNames: patient.firstNames,
      usedFirstName: patient.usedFirstName,
      birthDate: patient.birthDate,
      birthPlaceCode: patient.birthPlaceCode,
    })
  },
  postErrorToPartner (error) {
    this.isPartnerAccess() && window.parent.postMessage({ error }, '*')
  },
}