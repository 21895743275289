<template>
  <article class="partner-empty-page">
    <app-container class="partner-empty-page__content">
      <main class="partner-empty-page__content__text">
        <section
          v-if="isPartnerAccess"
          v-test="'partner-empty-page-content'"
        >
          Merci d'utiliser l'application tierce appelante<br>
          (exemple: cette dernière pourrait cacher ou détruire l'iframe sur traitement en succès de l'event 'message' que nous lui renvoyons)
        </section>
      </main>
    </app-container>
  </article>
</template>

<script>
import AppContainer from '@/components/ui/container/AppContainer.vue'
import partnerService from '@/services/partnerService'

export default {
  name: 'PartnerEmptyPage',
  components: { AppContainer },
  computed: {
    isPartnerAccess () {
      return partnerService.isPartnerAccess()
    },
  },
}
</script>
<style lang="scss" scoped>
.partner-empty-page {
  height: 100%;
  overflow: auto;

  &__content {
    padding: map-get($spacers, 6) map-get($spacers, 6) 0 map-get($spacers, 6) !important;
    position: relative;
    height: 100%;
  }
}
</style>