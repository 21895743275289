import { getGenderFromCivility } from '@/utils/functions/refs'
import { CONTACTS_TYPES } from '@/constants'
import { PRACTITIONER, MEDICAL_SECRETARY, FINANCIAL_SECRETARY } from '@/constants/userProfiles'
import { mapStoreResetter, RESET_MUTATION } from '@/utils/functions/store'

import axios from 'axios'
import { patchToAPI, postToAPI } from '@/services/api.js'
import partnerService from '@/services/partnerService'
import router from '@/router/instance'

import { ROUTE_NAMES as AUTHENTICATION_ROUTE_NAMES } from '@/modules/authentication/constants'

import User from '@/models/user/User'
import NovaTools from '@/nova-tools/NovaTools'

export const state = () => {
  const initialState = {
    authPayload: null,
    lastAuthUsername: null,
    lastRememberMe: true,
    isPartnerAccess: partnerService.isPartnerAccess(),
  }

  const authPayload = localStorage.getItem('authPayload')
  if (authPayload !== null) {
    initialState.authPayload = JSON.parse(authPayload)
  }

  const lastAuthUsername = localStorage.getItem('lastAuthUsername')
  if (lastAuthUsername !== null) {
    initialState.lastAuthUsername = JSON.parse(lastAuthUsername)
  }

  const lastRememberMe = localStorage.getItem('lastRememberMe')
  if (lastRememberMe !== null) {
    initialState.lastRememberMe = JSON.parse(lastRememberMe)
  }

  return initialState
}

export const getters = {
  /**
   * Utilisateur connecté à l'appli
   * Ajouté dans le store au login
   * l'api fourni un token et un objet user
   * @returns {Object} l'objet user contenant toutes les datas de l'utilisateur fournies par l'API
   */
  getCurrentUser: (state, getters, rootState, rootGetters) => {
    if (! state.authPayload) {
      return
    }
    const userOrganisationPractitioner = rootGetters['users/organisationPractitioners']
    const currentUser = userOrganisationPractitioner.find(user => user.username === state.authPayload.user.username)

    return currentUser
      ? currentUser
      : new User(state.authPayload.user)
  },
  isLogged (state) {
    return !! state.authPayload
  },
  isPartnerAccess (state) {
    return state.isPartnerAccess
  },
  getCurrentUserProfile (state, getters) {
    if (getters.isCurrentUserPractitioner) {
      return PRACTITIONER
    }
    if (getters.isCurrentUserSecretary) {
      return MEDICAL_SECRETARY
    }
    if (getters.isCurrentUserFinancialSecretary) {
      return FINANCIAL_SECRETARY
    }
    return null
  },
  isCurrentUserPractitioner (state) {
    return state?.authPayload?.user?.roleRefs?.some(
      ref => ref.value === 'ROLE_PRACTITIONER',
    )
  },
  isCurrentUserSecretary (state) {
    return state?.authPayload?.user?.roleRefs?.some(
      ref => ref.value === 'ROLE_SECRETARY_MEDICAL',
    )
  },
  isCurrentUserFinancialSecretary (state) {
    return state?.authPayload?.user?.roleRefs?.some(
      ref => ref.value === 'ROLE_SECRETARY_FINANCIAL',
    )
  },
  getAuthPayload (state) {
    return state.authPayload
  },
  /**
   * Renvoie le type de l'utilisateur connecté ('medical' ou 'other')
   * @returns {string} le type de l'utilisateur
   */
  getCurrentUserType (state, getters) {
    return getters.getIsUserPractitionner ? CONTACTS_TYPES.MEDICAL.value : CONTACTS_TYPES.OTHER.value
  },
  /**
   * Retourne le genre de l'utilisateur connecté :
   * @returns {String} le genre du user ('female' ou 'male')
   */
  getCurrentUserGender (state, getters) {
    return getGenderFromCivility(getters.getCurrentUser.contact.civility)
  },
  getGcu: (state) => {
    return state?.authPayload?.gcu
  },
  hasRpps (state) {
    return !! state?.authPayload?.user?.contact?.practitionerProfile?.rpps
  },
  getCurrentUserTopics: (state) => {
    const topics = state?.authPayload?.topics

    return topics
      ? topics
      : {}
  },
  getPatientMeasureUserSettings: (state, getters) => {
    return getters.getCurrentUser.patientMeasureUserSettings ? getters.getCurrentUser.patientMeasureUserSettings : []
  },
  hasPermission: (state) => (permission) => {
    const permissions = state?.authPayload?.permissions
    if (! permissions) {
      return false
    }

    return permissions.indexOf(permission) > - 1
  },
}
export const mutations = {
  ...mapStoreResetter(state),
  TOKEN_REFRESH (state, authPayload) {
    state.authPayload = authPayload

    // Sauvegarde le contenu du payload d'authentification
    localStorage.setItem('authPayload', JSON.stringify(authPayload))
  },
  LOGIN_SUCCESS (state, { username, rememberMe }) {
    state.lastAuthUsername = rememberMe ? username : ''
    state.lastRememberMe = rememberMe

    // Sauvegarde l'état du bouton "se souvenir de moi"
    localStorage.setItem('lastRememberMe', JSON.stringify(rememberMe))

    // Sauvegarde le pseudo renseigné
    if (rememberMe) {
      localStorage.setItem('lastAuthUsername', JSON.stringify(username))
    } else {
      localStorage.removeItem('lastAuthUsername')
    }
  },
  LOGOUT (state) {
    state.authPayload = null

    // Suppression du contenu du payload d'authentification
    localStorage.removeItem('psc')
    localStorage.removeItem('authPayload')
    if (partnerService.isPartnerAccess()) {
      partnerService.setPartnerAccessLoggedOut(true)
    }
    delete axios.defaults.headers.common['Authorization']
    NovaTools.security.codePin.removeMemorizedCode()

    if (router.currentRoute.name !== AUTHENTICATION_ROUTE_NAMES.CONNECTION) {
      router.push({ name: AUTHENTICATION_ROUTE_NAMES.CONNECTION })
    }
  },
  LOGIN_FAILURE (state) {
    state.authPayload = null
  },
  LOGOUT_NOCGU () {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('refresh_token_expiration')
    localStorage.removeItem('gcuValidationNeeded')
    NovaTools.security.codePin.removeMemorizedCode()
  },
  PARTNER_STATUS (state, isPartnerAccess) {
    state.isPartnerAccess = isPartnerAccess
  },
}

export const actions = {
  isGcuAccepted (context, data) {
    return data.gcu && data.gcu.accepted === true
  },
  async refreshTokenAndFetchData ({ dispatch }, data) {
    const promises = [dispatch('refreshTokenStorage', data)]

    if (await dispatch('isGcuAccepted', data)) {
      promises.push(
        dispatch('app/getAppDatas', null, { root: true }),
        dispatch('agenda/fetchAgendaDatas', null, { root: true }),
      )
    }
    await Promise.all(promises)
  },
  async authenticate ({ commit, dispatch }, { user, rememberMe }) {
    const { data } = await postToAPI('/api/authentication_token',
      {
        username: user.username,
        password: user.password,
      },
      { errorHandle: false },
    )
    if (! data.token) {
      commit('LOGIN_FAILURE')
      return data
    }
    await dispatch('refreshTokenAndFetchData', data)
    commit('LOGIN_SUCCESS', {
      username: user.username,
      rememberMe,
    })
    return data
  },
  async authenticatePSC ({ commit, dispatch }, params) {
    const { data } = await postToAPI('/api/authentication_token', params, { headers: { 'X-Requested-With': 'pro-sante-connect' } })
    if (! data.token) {
      commit('LOGIN_FAILURE')
      return data
    }
    await dispatch('refreshTokenAndFetchData', data)
    return data
  },
  async associatePSC ({ commit, state }, params) {
    const { data } = await patchToAPI(`${state.authPayload.user['@id']}/psc_attachment`, { data: params })
    commit('TOKEN_REFRESH', {
      ...state.authPayload,
      user: data,
    })
    return data
  },
  async authenticatePartner ({ commit, dispatch }, params) {
    commit('PARTNER_STATUS', true)
    const data = await dispatch('fetchPartnerAuthData', params.partnerKey)
    if (! data.token) {
      commit('LOGIN_FAILURE')
      return data
    }
    await dispatch('refreshTokenAndFetchData', data)
    return data
  },
  async fetchPartnerAuthData (context, partnerKey) {
    const { data } = await postToAPI('/api/v1/partner/auth', { payload: partnerKey })
    return data
  },
  async resetUserPassword (context, { token, password }) {
    await postToAPI('/users/password/reset', {
      token,
      password,
    })
  },
  async updateUserPassword ({ getters, commit, state }, { password }) {
    await postToAPI('/api/password/personalize', { password })
    if (! getters.getCurrentUser.isPersonalizedPwd) {
      commit('TOKEN_REFRESH', {
        ...state.authPayload,
        user: {
          ...getters.getCurrentUser,
          isPersonalizedPwd: true,
        },
      })
    }
  },
  refreshTokenStorage ({ commit }, authPayload) {
    commit('TOKEN_REFRESH', authPayload)
    NovaTools.security.permissions.setPermissions(authPayload?.permissions)
  },
  endSession ({ dispatch }) {
    NovaTools.notify.error('Veuillez vous reconnecter', {
      title: 'Votre session a expiré',
      timeout: - 1,
    })
    dispatch('logout')
  },
  logout ({ commit }) {
    commit('LOGOUT')

    // Récupère les mutations avec RESET injecté
    const modulesResetMutations = Object.keys(this._mutations)
      .filter(mutationName => mutationName.endsWith('/' + RESET_MUTATION))

    // Appele la mutation RESET pour chaque module qui en dispose
    modulesResetMutations
      .forEach(resetMutation => this.commit(resetMutation))
  },
  logout_nocgu ({ commit, dispatch }) {
    dispatch('logout')
    commit('LOGOUT_NOCGU')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}