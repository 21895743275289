import ApiEntity from '@/models/ApiEntity'

import { getFromAPI } from '@/services/api'

const ENTITY_URL = '/api/identity_devices'

/**
 * @typedef {Object} InsIdentityDeviceType
 * @property {String} value
 * @property {Boolean} strongTrustLevel
 */
export default class InsIdentityDevice extends ApiEntity {
  /**
   * @param {InsIdentityDeviceType} options
   */
  constructor (options = {}) {
    super(options)
    this.value = options.value || null
    this.strongTrustLevel = options.strongTrustLevel || false
    this.attest = options.attest || false
  }

  /**
   * Permet de récupérer l'ensemble des dispositifs d'identités depuis l'API
   * @returns {InsIdentityDevice[]}
   */
  static async fetchAll () {
    const { data } = await getFromAPI(ENTITY_URL)
    // Pour un affichage plus cohérent, les dispositifs à faible niveau de confiance seront affichés en dernier
    return data['hydra:member'].map(device => new InsIdentityDevice(device)).sort((a, b) => b.strongTrustLevel - a.strongTrustLevel)
  }

  /**
   * Permet de récupérer un dispositif d'identité à partir de son UUID
   * @param {String} uuid
   * @returns {InsIdentityDevice}
   */
  static async fetch (uuid) {
    const { data } = await getFromAPI(ENTITY_URL + '/' + uuid)
    return new InsIdentityDevice(data)
  }
}