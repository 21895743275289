<template>
  <div class="login">
    <authentication-form-wrapper
      v-if="displayLoginForm"
      :title="FORM_HEADINGS.CONNECTION.title"
      :text="FORM_HEADINGS.CONNECTION.text"
      :form="LoginForm"
      submit-text="Se connecter"
    />
    <choice-divider v-if="displayLoginForm" />
    <psc-button v-if="displayLoginForm" />
    <div v-if="isPartnerAccess && !isPartnerAccessLoggedOut">
      <app-circular-loader />
      Identification partenaire en cours ... merci de patienter
    </div>
    <div v-if="isPartnerAccessLoggedOut">
      Vous avez été déconnecté de votre accès partenaire, merci de relancer un appel au service partenaire.
    </div>
  </div>
</template>

<script>
import AuthenticationFormWrapper from '@/modules/authentication/components/AuthenticationFormWrapper.vue'
import LoginForm from '@/modules/authentication/forms/LoginForm.vue'
import ChoiceDivider from '@/components/ui/divider/ChoiceDivider.vue'
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'

import { FORM_HEADINGS } from '@/modules/authentication/constants'
import PscButton from '@/modules/authentication/components/PscButton'
import { mapActions, mapGetters } from 'vuex'

import partnerService from '@/services/partnerService'

export default {
  name: 'Login',
  components: {
    PscButton,
    ChoiceDivider,
    AuthenticationFormWrapper,
    AppCircularLoader,
  },
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (partnerService.isPartnerAuthentication(to)) {
        partnerService.removeLocalPartnerAccessLoggedOut()
        await vm.routePartnerAccess(to.query)
        return
      }
      if (vm.isPscAuthentication(to)) {
        const pscLocal = vm.getPscFromLocalStorage()
        if (vm.checkStateCsrf(pscLocal.state)) {
          await vm.authenticatePSC(to.query)
          await vm.$router.push('/')
        }
      }
    })
  },
  data () {
    return {
      FORM_HEADINGS,
      LoginForm,
    }
  },
  computed: {
    ...mapGetters('auth', ['isPartnerAccess']),
    isPartnerAccessLoggedOut () {
      return partnerService.isPartnerAccessLoggedOut()
    },
    displayLoginForm () {
      return ! this.isPartnerAccess && ! this.isPartnerAccessLoggedOut
    },
  },
  methods: {
    ...mapActions('auth', ['authenticatePSC', 'authenticatePartner']),
    isPscAuthentication (to) {
      return (to.path === '/' && to.query?.code)
    },
    getPscFromLocalStorage () {
      return JSON.parse(localStorage.getItem('psc'))
    },
    checkStateCsrf (state) {
      return (state === this.getPscFromLocalStorage().state)
    },
    async routePartnerAccess (query) {
      await this.authenticatePartner(query)
      this.$router.push(await partnerService.getRoutePartnerAccess(query))
    },
  },
}
</script>
<style lang="scss" scoped>
  .login {
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
</style>